

.ntf-Wishlist .leftSidebar .ApplyBtn::after {
    display: none;
}

.ntf-Wishlist .wishlist-card.leftSidebar{
    padding: 20px;
}

@media (max-width: 767px) {
    .ntf-Wishlist .fs-30{
        font-size: 18px;
    }

    .ntf-Wishlist .legendryBtn-green .innerText{
        font-size: 18px;
    }
    
    .ntf-Wishlist .mobile-cards{
        background: #202E4B;
        border-radius: 10px;
        padding: 10px;
    }

    .ntf-Wishlist .mobile-cards .inner-card{
        border: 0.5px solid rgba(255, 255, 255, 0.3);
        border-radius: 4px;
        padding: 10px;

    }

    .ntf-Wishlist .mobile-cards .nft-img{
        border: 0.5px dashed #55DBCB;
        border-radius: 4px;
        width: 60px;
        height: 60px;
        padding: 5px;
    }

    .ntf-Wishlist .mobile-cards .nft-img img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;

    }

    .ntf-Wishlist .mobile-cards .inputBox{
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 4px;
        width: 80px;
        height: 40px;

    }

    .ntf-Wishlist .mobile-cards .inputBox input{
        background-color: transparent !important;
        outline:0 !important;
        border: 0 !important;
        width: 100%;
        height: 100%;
        text-align: center !important;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: rgba(255,255,255, 0.7);
    }

    .ntf-Wishlist .lineClamp{
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }

  
}