.Terms{
    /* background: url(../../assets/term-privacy-bg.png), #000; */
    /* background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center; */
    padding-top: 100px;
    /* background-attachment: fixed; */
    font-family: "Poppins";
}

.Terms .fs-70{
    font-size: 70px;
}

.Terms ul{
    list-style-type: disc !important;
    padding: 1rem;
}

.Terms ul li::marker{
    color: #fff;
}


@media (max-width : 767px) {
    .Terms .fs-70 {
        font-size: 32px;
    }
    
}