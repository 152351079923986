html,
body {
  height: 100%;
  width: 100%;
}

*,
ul,
ol {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: linear-gradient(90.03deg, #030209 0.02%, #081a3e 99.96%);
  font-family: "Poppins", sans-serif;
}

.pointer {
  cursor: pointer;
}

a:hover {
  text-decoration: wavy;
}

.yellow-color {
  color: #FFFF00 !important
}

/* font size */

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-25 {
  font-size: 25px;
}

.fs-26 {
  font-size: 26px;
}

.fs-28 {
  font-size: 28px;
}

.fs-30 {
  font-size: 30px;
}

.fs-32 {
  font-size: 32px;
}

.fs-34 {
  font-size: 34px;
}

.fs-36 {
  font-size: 36px;
}

.fs-38 {
  font-size: 38px;
}

.fs-40 {
  font-size: 40px;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

/* font size end */

/* custom spacing and designs */
.mx-15 {
  margin-right: 15px;
  margin-left: 15px;
}

.ml-15 {
  margin-left: 15px;
}

input:focus,
select:focus {
  outline: 0;
  box-shadow: none !important;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-100 {
  margin-top: 100px;
}

.textWhite80 {
  color: rgba(255, 255, 255, 0.8);
}

.textWhite70 {
  color: rgba(255, 255, 255, 0.7);
}

.textGreen {
  color: #55dbcb;
}

.pl-30 {
  padding-left: 30px !important;
}

/* custom spacing end */

/* navbar style */

.topNav {
  /* padding-top: 20px; */
  margin-bottom: 80px;
}

.topNav nav .nav-link {
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 23px;
  /* letter-spacing: 0.05em; */
  color: #fff !important;
  position: relative;
  cursor: pointer;
  padding: 0px;
}


.topNav nav .nav-link.textGreen{
  color: #55dbcb !important;
}

.topNav nav .nav-link.active {
  position: relative;
}

.topNav nav .nav-link.active::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -16px;
  left: 0;
  background-color: #55dbcb;
}

.topNav .horizontalLine {
  position: relative;
  width: 100%;
  display: flex;
}

.topNav .horizontalLine::after {
  content: "";
  height: 1px;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  bottom: -16px;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  background: none;
}

.topNav nav .socialIcons {
  list-style: none;
  display: flex;
  margin-left: 15px;
}

.topNav nav .socialIcons li:not(:last-child) {
  margin-right: 10px;
}

/* navbar style end */

/* header style */
.socialIcons li img {
  width: 35px;
  margin-top: 2px;
}

.topHeader {
  background-color: rgba(255, 255, 255, 0.05);
  padding-bottom: 45px;
}

.topHeader .breadCrumb {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 40px;
  background: linear-gradient(272.84deg,
      rgba(150, 180, 220, 0.1) -10.58%,
      rgba(201, 237, 213, 0.1) 30.35%,
      rgba(209, 246, 212, 0.1) 42.37%,
      rgba(254, 221, 194, 0.1) 64.05%,
      rgba(254, 200, 188, 0.1) 74.68%,
      rgba(253, 68, 151, 0.1) 107.15%);
  border-radius: 20px;
  margin-bottom: 80px;
}

.breadCrumb .parent {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.7);
}

.breadCrumb .child {
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: #fff;
}

.topHeader .input-with-icon {
  position: relative;
  width: 100%;
  height: 60px;
}

.input-with-icon input {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 30px;
  color: #fff;
  padding-left: 80px;
  font-size: 18px;
  color: #fff !important;
}

.input-with-icon img {
  position: absolute;
  top: 10px;
  left: 30px;
}

.input-with-icon input:focus {
  background-color: inherit;
}

.input-with-icon input::placeholder {
  color: #fff;
}

.topHeader .input-with-icon .search-btn {
  background-color: #55dbcb;
  color: #0a090f;
  width: 250px;
  height: 50px;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 30px;
}

.smallScreen {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex: 1;
  align-items: center;
}

.smallScreen li:nth-child(2) {
  margin-left: 25px;
  margin-right: 10px;
}

.flex-1 {
  flex: 1;
}

.connectBtn {
  background-color: #55dbcb;
  border-radius: 0 !important;
  font-family: "Poppins";
  width: 217px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.05em;
  cursor: pointer;
  text-transform: uppercase;
}

.connectBtn:hover {
  background: #399287;
}

.connectBtn-innerText {
  background: linear-gradient(90.03deg, #030209 0.02%, #081a3e 99.96%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-transform: uppercase;

}

.topHeader .dropdown-menu,
.landingPage .dropdown-menu {
  background: rgba(255, 255, 255, 0.05) !important;
  border: 0.5px solid rgba(255, 255, 255, 0.3) !important;
  border-radius: 4px !important;
}

.landingPage .dropdown-menu {
  width: 200px;
  color: white !important;
}

.topHeader .dropdown-item,
.landingPage .dropdown-item {
  cursor: pointer;
  color: #fdfdfd !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 24px !important;
}

/* header style end */

/* Home style */

.Home {
  margin-bottom: 90px;
}

.css-v1jrxw-ContentComponent {
  height: 100%;
  align-items: center;
}

.Home .gemText,
.legendary-section .gemText {
  margin-top: 80px;
  color: #fff;
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  position: relative;
  margin-bottom: 60px;
}

.Home .gemText::after,
.legendary-section .gemText::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: -20px;
  background-color: rgba(255, 255, 255, 0.3);
}

.Home .gemText .gradientText,
.legendary-section .gemText .gradientText {
  background-color: #55dbcb;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 25px;
  line-height: 32px;
}

.Home .sortBtn,
.legendary-section .sortBtn {
  background: rgba(196, 196, 196, 0.1) !important;
  border: 1px solid #ffffff;
  border-radius: 15px;
  width: 190px;
  height: 60px;
  color: #55dbcb;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 30px;
  cursor: pointer;
}

.Home .sortBtn .css-ght6qz-DropDown,
.legendary-section .sortBtn .css-ght6qz-DropDown {
  background-color: transparent !important;
}

.Home .sortBtn .react-dropdown-select-input,
.legendary-section .sortBtn .react-dropdown-select-input {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.Home .sortBtn .css-1lgmlwq-DropDown,
.legendary-section .sortBtn .css-1lgmlwq-DropDown {
  background: rgba(196, 196, 196, 0.1) !important;
  border: 1px solid #ffffff;
  border-radius: 15px;
  top: 58px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #55dbcb;
}

.Home .leftSidebar {
  background: #202e4b;
  border-radius: 4px;
  padding: 50px 40px;
}

.Home .home-pagination {
  position: absolute;
  bottom: -65px;
  background: linear-gradient(90.03deg, #030209 0.02%, #081a3e 99.96%);
}

.home-pagination .page-item.disabled .page-link,
.home-pagination .page-item .page-link {
  background-color: transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.05em;
  color: #fff;
}

.home-pagination .page-item.active .page-link {
  z-index: 2;
  color: #fff;
  background-color: #55dbcb !important;
  border-color: #55dbcb;
}

.leftSidebar .inputRange {
  margin-top: 60px;
  margin-bottom: 40px;
}

.inputRange .input-range__slider {
  background-color: #55dbcb;
  border: 0;
  width: 24px;
  height: 24px;
  margin-top: -16px;
}

.inputRange .input-range__track--active {
  background-color: #55dbcb;
}

.inputRange .input-range__label--value {
  top: -2.4rem;
}

.inputRange .input-range__label--max,
.inputRange .input-range__label--min {
  display: none;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.leftSidebar .ApplyBtn {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 4px;
  position: relative;
  background-color: transparent;
  border: 1px solid #55dbcb;
  cursor: pointer;
}

.leftSidebar .ApplyBtn::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  left: 0;
  bottom: -50px;
  cursor: pointer;
}

.leftSidebar .tabs {
  margin-top: 30px;
}

.leftSidebar .tab-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 30px;
  height: 50px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  padding: 13px 20px;
  cursor: pointer;
}

.leftSidebar .tabsData {
  padding: 16px;
}


.leftSidebar .tabsData input[type='search'] {
  width: 100%;
  border-radius: 30px;
  padding: 10px;
  padding-left: 35px;
}

.leftSidebar .tabsData .fa-search {
  position: absolute;
  top: 15px;
  left: 15px;
}

.leftSidebar .tabsScroll {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(250px - 100px);
}

.tabsScroll::-webkit-scrollbar {
  display: none;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-50 {
  margin-bottom: 50px;
}

/* gem card */

.card-section {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 40px;
  padding-left: 30px;
}

.card-section .gemCard {
  max-width: 510px;
  background: linear-gradient(90.03deg, #030209 0.02%, #081a3e 99.96%);
  border: 0.2px solid #ffffff;
  border-radius: 10px;
  position: relative;
  /* margin-bottom: 30px; */
}

.gemCard .gem-img {
  width: 100%;
  height: 330px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.gemCard .gem-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.gemCard .gemBody {
  padding: 40px 20px 20px;
}

.gemBody .price {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
}

.gemCard .gradientBtn {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #55dbcb;
  width: 179px;
  height: 40px;
  border-radius: 4px;
}

.gemCard .legendBadge {
  background: linear-gradient(90.03deg, #030209 0.02%, #081a3e 99.96%);
  border-radius: 30px;
  width: 200px;
  height: 44px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 15px;
  right: 20px;
}

/* gem card end */

/* Home style end */

/* footer style */

footer {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.8);
  padding-bottom: 16px;
}

footer .topBorder {
  /* border-top: 1px solid rgba(255, 255, 255, 0.7); */
  padding-top: 16px;
}

footer .privacy-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

footer .privacy-links a {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
}

/* footer style end */

/* stake style */

.legendryBtn-green {
  background: #6aff8b;
  border-radius: 0;
  padding: 14px 30px;
}

.legendryBtn-green .innerText {
  background: linear-gradient(90.03deg, #030209 0.02%, #081a3e 99.96%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  letter-spacing: 0.05em;
}

.mt-87 {
  margin-top: 87px;
}

.stakeCards {
  background: #202e4b;
  border-radius: 10px;
  padding: 25px;
  position: relative;
  margin-bottom: 40px;
}

.stakeCards::after {
  content: "";
  height: 100%;
  width: 1px;
  display: block;
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.stakeCards .stake-heading {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
}

.stakeCards .stake-innerCard {
  border: 0.5px solid #ffffff;
  border-radius: 4px;
  padding: 10px 20px;
}

.stake-innerCard .nftImg {
  width: 70px;
  height: 70px;
  padding: 5px;
  border-radius: 4px;
  position: relative;
  background-color: #55dbcb;
  border: 1px dashed #202e4b;
}

.stake-innerCard .nftImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
}

.stakeBtn {
  background: #a6ffb9;
  border-radius: 4px;
  width: 113px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stakeBtn.unstakeBtn {
  background: #ffa5a5;
  border-radius: 4px;
}

.stakeBtn-inner {
  background: linear-gradient(90.03deg, #030209 0.02%, #081a3e 99.96%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
}

.ml-50 {
  margin-left: 50px;
}

.stakeAllBtn {
  background: #6aff8b;
  border-radius: 4px;
  width: 145px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stakeVault .gradientBtn {
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
  font-weight: 700;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #0a090f;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 217px;
  height: 39px;
  border-radius: 0 !important;
  position: relative;
  background-color: #55dbcb;
  border: 1px solid transparent;
  margin-bottom: 87px;
}

.stakeVault .itemHeading {
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  letter-spacing: 0.02em;
  margin-bottom: 67px;
  color: #fff;
}

.stakeVault .card-section,
.legendary-section .card-section {
  margin-bottom: 80px;
  padding-left: 0;
}

.gradientBtn-outline {
  background-color: transparent;
  border: 1px solid #55dbcb;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.05em;
  width: 73px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.stakeVault .gemCard,
.legendary-section .gemCard {
  position: relative;
}

.stakeVault .gemCard::after,
.legendary-section .gemCard::after,
.MoreProduct .gemCard::after {
  content: "";
  background-color: #c4c4c4;
  width: 100%;
  height: 1px;
  display: block;
  position: absolute;
  bottom: 70px;
  left: 0;
}

.stakeVault .gemCard .footer-card,
.legendary-section .gemCard .footer-card,
.MoreProduct .gemCard .footer-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  letter-spacing: 0.02em;
  margin-top: 40px;
}

/* stake style end */

/* prouct detail style */

.productDetail {
  margin-top: 80px;
}

.productDetail .productCard {
  background: #202e4b;
  border-radius: 10px;
  padding: 25px 20px;
  width: 100%;
  height: 100%;
}

.productCard .productImg {
  width: 100%;
  height: 500px;
  border-radius: 10px;
  position: relative;
}

.productCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.productCard .cardHeading {
  font-weight: 700;
  font-size: 25px;
  line-height: 36px;
  letter-spacing: 0.02em;
  color: #fff;
  position: relative;
}

.productCard .cardHeading::after,
.productCard .price::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  left: 0;
  bottom: -21px;
}

.ml-30 {
  margin-left: 30px;
}

.productDetail .detailBadge {
  background: linear-gradient(90.03deg, #030209 0.02%, #081a3e 99.96%);
  border-radius: 30px;
  width: 122px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #fff;
  position: absolute;
  top: 25px;
  right: 50px;
  cursor: pointer;
}

.productDetail .productCard .chevron {
  position: absolute;
  bottom: 10px;
  left: 12px;
  display: inline-block;
  cursor: pointer;
}

.productDetail .productCard .price {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 60px;
}

.productCard .offSale {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
}

.offSale .innerText {
  background-color: #55dbcb;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

.productCard .alreadySold {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.productCard .connectBtn {
  width: 100%;
  height: 40px;
  font-weight: 400;
  font-size: 21px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  border: 0;
  background-color: #55dbcb;
  color: #0a090f;
  font-family: "Poppins";

}

.productCard .btn-with-icon {
  border: 1px solid #55dbcb;
  width: 100%;
  color: #fff;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: transparent;
  font-weight: 700;
}

.btn-with-icon span {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 18px;
}

.btn-with-icon span img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.pl-20 {
  padding-left: 20px;
}

.productDetail .detailTabs .nav-tabs {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 20px;
}

.detailTabs .nav-tabs .nav-item:not(:last-child) {
  margin-right: 50px;
}

.nav-item.mx-15 {
  margin-right: 4px;
  margin-left: 4px;
}

.navbar {
  padding: 10px 20px;
}

.detailTabs .nav-tabs .nav-item.show .nav-link,
.detailTabs .nav-tabs .nav-link.active,
.detailTabs .nav-tabs .nav-link {
  background-color: transparent;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
  position: relative;
  border: 0;
  padding: 0;
}

.detailTabs .nav-tabs .nav-link:focus,
.detailTabs .nav-tabs .nav-link:hover {
  border-color: transparent transparent transparent;
}

.detailTabs .nav-tabs .nav-link.active::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #55dbcb;
  position: absolute;
  bottom: -20px;
  left: 0;
}

.detailTabs .tab-content {
  padding-top: 40px;
}

.productDetail .detailTabs {
  margin-bottom: 80px;
}

.productDetail .MoreProduct {
  padding-bottom: 80px;
}

.MoreProduct .card-section {
  padding-left: 0;
}

.MoreProduct .card-section .legendBadge {
  width: 122px;
  height: 44px;
}

/* prouct detail style end */

/* view cart style */

.viewCart {
  padding-top: 80px;
  padding-bottom: 80px;
}

.viewCart .cartCard {
  background: rgba(32, 46, 75, 0.7);
  border-radius: 4px;
  padding: 20px 30px;
  position: relative;
}

.viewCart .before,
.checkout .before,
.ntf-Wishlist .before,
.faq .before,
.orderHistory .before {
  position: relative;
}

.viewCart .before::before,
.checkout .before::before,
.ntf-Wishlist .before::before,
.faq .before::before,
.orderHistory .before::before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  display: block;
  position: absolute;
  left: 0;
  bottom: -20px;
}

.mb-80 {
  margin-bottom: 80px;
}

.viewCart .before::before,
.checkout .before::before,
.ntf-Wishlist .before::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  bottom: -20px;
  left: 0;
}

.cartCard .cart-img {
  width: 120px;
  height: 100px;
  padding: 10px;
  border: 1px dashed #55dbcb;
}

.cart-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.cartCard .innerBox {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}

.green-btn-outline {
  border: 1px solid #55dbcb;
  color: #fff;
  border-radius: 4px;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.cartCard .connectBtn {
  color: #0a090f;
  font-weight: 400;
  font-size: 21px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  height: 40px;
  width: 100%;
  font-family: "Poppins";

}

.cartCard .description {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}

.green-btn-outline:hover {
  color: #fff;
}

.viewCart .legendryBtn-green {
  background: #6aff8b;
  padding: 7px 15px;
}

.viewCart .legendryBtn-green .innerText {
  font-size: 18px;
  line-height: 24px;
}

/* view cart style end */

/* checkout style */

.checkout {
  padding-top: 80px;
  padding-bottom: 80px;
}

.checkout form {
  margin-top: 60px;
}

.checkout form .input-field {
  position: relative;
  width: 100%;
  height: 70px;
}

.checkout form input {
  width: 100%;
  height: 100%;
  border: 0;
  outline: 0;
  padding: 30px;
  background: rgba(255, 255, 255, 0.05);
  border: 0.5px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.7);
  caret-color: #55dbcb;
}

.checkout form .input-field label {
  position: absolute;
  top: 25px;
  left: 30px;
  color: rgba(255, 255, 255, 0.7);
  transition: 0.3s all;
  cursor: text;
  transition: 0.3s ease all;
  -moz-transition: 0.3s ease all;
  -webkit-transition: 0.3s ease all;
}

.checkout input:focus~label,
.checkout textarea:focus~label,
.checkout form input:placeholder-shown~label,
.checkout form .input-field textarea:placeholder-shown~label,
.checkout form input~label,
.checkout form .input-field textarea~label {
  font-size: 13px;
  top: 4px;
}

textarea:focus,
input {
  outline: 0;
}

.checkout form .input-field textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  border: 0.5px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  outline: 0;
  caret-color: #55dbcb;
  padding: 30px;
  color: rgba(255, 255, 255, 0.7);
  resize: none;
}

.checkout .orderCard {
  margin-top: 60px;
  background: #202e4b;
  border-radius: 10px;
  padding: 40px;
}

.checkout .orderCard .inner-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  padding: 16px 15px;
}

.checkout .orderCard .inneBox-dashed {
  background: linear-gradient(272.84deg,
      rgba(150, 180, 220, 0.05) -10.58%,
      rgba(201, 237, 213, 0.05) 30.35%,
      rgba(209, 246, 212, 0.05) 42.37%,
      rgba(254, 221, 194, 0.05) 64.05%,
      rgba(254, 200, 188, 0.05) 74.68%,
      rgba(253, 68, 151, 0.05) 107.15%);
  border: 0.5px dashed #55dbcb;
  border-radius: 4px;
}

.orderCard .inneBox-dashed select {
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  padding: 16px 15px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
}

.orderCard .inneBox-dashed select option {
  color: #030209;
}

.checkBox-contain {
  display: block;
  position: relative;
  margin-bottom: 0px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mb-70 {
  margin-bottom: 70px;
}

/* Hide the browser's default checkbox */
.checkBox-contain input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid #55dbcb;
  box-sizing: border-box;
  border-radius: 4px;
}

/* When the checkbox is checked, add a blue background */
.checkBox-contain input:checked~.checkmark {
  background: rgba(255, 255, 255, 0.1);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkBox-contain input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkBox-contain .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 11px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.orderCard .gradientBtn-outline {
  height: 60px;
  color: #fff;
}

.orderCard .gradientBtn-outline:hover {
  color: #fff;
}

/* checkout style end */

/* nft wishlist checkout style */

.ntf-Wishlist {
  padding-top: 80px;
  padding-bottom: 80px;
}

.ntf-Wishlist .wishlist-card {
  background: #202e4b;
  border-radius: 10px;
  padding: 40px;
}

.ntf-Wishlist .wishlist-card .inner-card {
  border: 0.5px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  padding: 10px;
}

.ntf-Wishlist .inner-card .wishlist-img {
  border: 0.5px dashed #55dbcb;
  border-radius: 4px;
  width: 400px;
  height: 140px;
  padding: 10px;
  margin-right: 30px;
}

.inner-card .wishlist-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.ntf-Wishlist .inner-card .wishlist-description {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #fff;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 350px;
}

.ntf-Wishlist .inner-input input {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  width: 100%;
  height: 40px;
  background-color: transparent;
  padding: 8px 20px;
  color: rgba(255, 255, 255, 0.7);
}

/* nft wishlist checkout style end */

/* faq */

.faq {
  padding-top: 80px;
  padding-bottom: 80px;
}

.faq .faqCard {
  background: #202e4b;
  border-radius: 10px;
  padding: 40px;
  margin-top: 60px;
}

.faq .faqTabs .nav-tabs {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 20px;
}

.faqTabs .nav-tabs .nav-item:not(:last-child) {
  margin-right: 50px;
}

.faqTabs .nav-tabs .nav-item.show .nav-link,
.faqTabs .nav-tabs .nav-link.active,
.faqTabs .nav-tabs .nav-link {
  background-color: transparent;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
  position: relative;
  border: 0;
  padding: 0;
}

.faqTabs .nav-tabs .nav-link:focus,
.faqTabs .nav-tabs .nav-link:hover {
  border-color: transparent transparent transparent;
}

.faqTabs .nav-tabs .nav-link.active::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #55dbcb;
  position: absolute;
  bottom: -20px;
  left: 0;
}

.faq .tab-content {
  padding-top: 40px;
}

.faq .faqTabs {
  margin-bottom: 80px;
}

.faq .faq-inner-card {
  border-bottom: 1px solid #dce0e8;
  padding: 40px 0;
  margin-bottom: 12px;
  list-style: none;
  position: relative;
  cursor: pointer;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #fff;
}

.faq .faq-open-icon {
  position: absolute;
  top: 18px;
  right: 10px;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  width: 40px;
  height: 40px;
}

.faq details>summary {
  list-style: none;
  color: #fff;
  font-weight: 700;
  font-size: 25px;
  line-height: 36px;
}

.question summary {
  color: #55dbcb;
  font-weight: 700;
  font-size: 25px;
  line-height: 36px;
}

.question .faq-card-spoiler {
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-top: 16px;
  color: #fff;
}

.question>summary::-webkit-details-marker {
  display: none;
}

/* faq end */

/* landing page style */

.landingPage .header,
.landingPage .About-us,
.landingPage .housestakeBg,
.landingPage .gemsVault,
.landingPage .upcoming-events {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.landingPage .header {
  background-image: url("./assets/headerBg.png");
  background-size: cover;
  background-position-x: center;
  background-position-y: bottom;
  height: 750px;
}

.landingPage .About-us {
  background-image: url("./assets/section-1-bg-trans.png");
  /* height: 875px; */
  padding-top: 120px;
  padding-bottom: 90px;
}

.landingPage .upcoming-events {
  /* height: 1080px; */
  padding-top: 120px;
  padding-bottom: 90px;
  background-image: linear-gradient(180deg,
      #000000 0%,
      rgba(8, 26, 62, 0) 48.86%,
      #000000 100%),
    url("./assets/upcommingBg.png");
}

.landingPage .housestakeBg {
  /* height: 1080px; */
  padding: 100px 0px;
  display: flex;
  align-items: center;
  background-image: linear-gradient(180deg,
      #000000 0%,
      rgba(8, 26, 62, 0) 48.86%,
      #000000 100%),
    url("./assets/sectionBg-1.png");
}

.landingPage .topHeading .bannerText {
  font-weight: 700;
  font-size: 70px;
  line-height: 90px;
  color: #ffffff;
  margin-top: 35%;
}

.landingPage .topHeading .bannerTextDes {
  font-size: 32px;
  font-weight: 600;
  color: #ffffff;
  margin-top: 30px;
  margin-bottom: 30px;
}

.landingPage .topHeading .bannerText span {
  color: #55dbcb;
}

.landingPage .topHeading {
  /* margin-left: 30px; */
}

.landingPage .topHeading .connectBtn {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: #000000;
  text-transform: uppercase;
  line-height: 28px;
  border-radius: 0;
  width: 217px;
  height: 39px;
  padding: 0;
  font-family: "Poppins";

}

.sectionHeading {
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #ffffff;
  margin-bottom: 40px;
}

.sectionHeading span {
  color: #55dbcb;
}

.sectionDiscription {
  font-size: 20px;
  color: #ffffff;
}

.linkButton {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: #000000;
  text-transform: uppercase;
  line-height: 28px;
  border-radius: 0;
  padding: 10px 35px;
  margin-top: 20px;
  background: #55dbcb;
}

.linkButton:hover {
  background: #399287;
  color: #000000 !important;
}

.btnDesign {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: #000000;
  text-transform: uppercase;
  line-height: 28px;
  border-radius: 0;
  padding: 6px 35px;
  margin-top: 20px;
  background: #55dbcb;
}

.btnDesign:hover {
  background: #399287;
  color: #000000 !important;
}

.sliderSectionDesign {
  padding-left: 60px;
}

.mb-n80 {
  margin-bottom: -80px !important;
}

.bg-blur {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(30px);
  height: 55px;
}

.landingPage .connectBtn {
  width: 200px;
  height: 68px;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
  font-family: "Poppins";

}

.landingPage .heading {
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 55px;
  color: #fff;
}

.landingPage a:hover {
  text-decoration: none;
  color: #55dbcb;
}

.landingPage .housestakeBg {
  position: relative;
}

.landingPage .stakeNft {
  display: flex;
}

.landingPage .stakeNft .stakeCard {
  width: 100%;
  height: 320px;
}

.landingPage .stakeNft .stakeCard:not(:last-child) {
  margin-right: 30px;
}

.landingPage .stakeNft .stakeCard {
  border-bottom: 1px solid #fff;
}

.stakeNft .borderBotttom-30 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.stakeNft .stakeCard .nftimg {
  width: 100%;
  height: 260px;
}

.stakeNft .connectBtn {
  width: 213px;
}

.stakeCard .nftimg img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 4px;
}

.landingPage .gemsVault,
.landingPage .upcoming-events {
  padding-top: 120px;
  padding-bottom: 120px;
}

.landingPage .upcoming-events {
  margin-bottom: 0px;
}

.landingPage .gemsVault .vaultCard {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 30px;
  height: 450px;
  border-radius: 10px;
}

.vaultCard .vault-img {
  width: 100%;
  height: 350px;
}

.vaultCard .vault-img img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 10px;
}

.landingPage .upcoming-events .upcoming-img {
  width: 100%;
  /* height: 540px; */
  height: auto;
  position: relative;
}

.landingPage .upcoming-events .upcoming-img::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.4) 64.5%,
      rgba(0, 0, 0, 0.5) 100%);
  border-radius: 65px;
}

.upcoming-events .upcoming-img img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.upcoming-events .upcoming-img .img-upper-text {
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  letter-spacing: 0.05em;
  color: #f5f5f5;
  position: absolute;
  left: 20px;
  bottom: 50px;
  width: 840px;
  z-index: 1;
}

.landingPage .About-us .slick-list,
.landingPage .About-us .slick-slider,
.landingPage .About-us .slick-track {
  right: 0%;
}

.slick-slide img {
  border-radius: 65px;
  width: 735px;
  height: 500px;
}

.legendaryGameText {
  font-size: 22px;
  color: #fff;
  padding: 12px 0px;
}

.sliderSectionDesign .progres {
  display: none;
}

.slick-dots,
.slick-prev:before {
  display: none !important;
}

.landingPage .slick-prev {
  display: none !important;
}

.landingPage .slick-next {
  top: 102% !important;
  right: 88px !important;
}

.landingPage .slick-next::before {
  content: " ";
  background-image: url("./assets/longArrow.svg");
  display: block;
  width: 66px;
  height: 16px;
  background-repeat: no-repeat;
}

.landingPage .progres {
  position: relative;
  width: 390px;
  left: 150px;
}

.landingPage .progres .prog-wrapper {
  height: 2px !important;
  background-color: #fff !important;
}

.landingPage .progres1 {
  position: relative;
  width: 390px;
}

.landingPage .progres1 .prog-wrapper {
  height: 2px !important;
  background-color: #fff !important;
}

.navbar-dark .navbar-nav .nav-link {
  text-align: center;
  line-height: 22px;
  font-weight: 600;
}

/* landing page style end */

/* queries */
@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1330px;
  }

  .leftSidebar .tabs .tab-filter {
    height: 60px;
  }

  .card-section {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }

  .productDetail .productCard {
    padding: 50px 40px;
  }
}

@media (max-width: 1700px) {
  .card-section {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    place-items: center;
  }

  .stake-innerCard .fs-18 {
    font-size: 14px;
  }
}

@media (max-width: 1399px) {
  .card-section .gemCard {
    width: 340px;
  }

  .gemCard .gradientBtn {
    width: 160px;
    font-size: 15px;
  }

  .gemCard .fs-25 {
    font-size: 20px;
  }
}

@media (min-width: 1024px) and (max-width: 1399px) {
  .landingPage .header {
    height: 699px;
  }

  .landingPage .topHeading {
    font-size: 48px;
    line-height: 100px;
    margin-top: 175px;
  }

  .landingPage .About-us .slick-list,
  .landingPage .About-us .slick-slider,
  .landingPage .About-us .slick-track {
    /* right: -50px; */
  }

  .landingPage .About-us {
    overflow-x: hidden;
    height: auto;
  }

  .landingPage .About-us .slick-next {
    right: 8% !important;
  }

  .landingPage .housestakeBg {
    height: 880px;
  }

  .landingPage .stakeNft .stakeCard {
    height: 360px;
  }

  /* .stakeNft .stakeCard .nftimg {
    height: 215px;
  } */
}

@media (max-width: 768px) {
  .stakeVault .stakeCards::after {
    height: 1px;
    width: 100%;
    display: block;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .landingPage .progres {
    left: 0;
    width: 140px;
  }

  .landingPage .stakeNft .stakeCard {
    height: 350px;
  }

  /* .stakeNft .stakeCard .nftimg {
    height: 255px;
  } */

  .landingPage .progres1 {
    width: 140px;
  }

  .fs-25,
  .fs-36 {
    font-size: 21px;
  }

  .fs-22 {
    font-size: 16px;
  }

  .landingPage .header {
    background-size: 100% 100%;
    height: auto;
  }

  .landingPage .topHeading {
    font-size: 40px;
    line-height: 65px;
    margin-top: 170px;
  }

  .topNav .horizontalLine::after {
    bottom: -13px;
  }

  .landingPage .connectBtn {
    width: 200px;
    height: 50px;
    font-size: 15px;
    line-height: 22px;
  }

  .landingPage .About-us,
  .landingPage .housestakeBg,
  .landingPage .upcoming-events {
    height: auto;
  }

  .landingPage .housestakeBg {
    padding: 120px 0;
  }

  .landingPage .gemsVault .vaultCard {
    padding: 20px;
  }

  .landingPage .upcoming-events .upcoming-img {
    height: auto;
  }

  .upcoming-events .upcoming-img .img-upper-text {
    font-size: 20px;
  }

  /* nav */

  nav .navbar-collapse.show {
    position: absolute;
    top: -58%;
    left: -5%;
    height: 100vh;
    width: 100vw;
    background: linear-gradient(90.03deg, #030209 0.02%, #081a3e 99.96%);
    z-index: 111;
  }

  nav li.mx-15 {
    margin: 0;
  }

  .topNav nav .nav-link.active::after {
    display: none;
  }

  nav .mobileCross {
    z-index: 11;
    background-color: transparent;
    outline: 0;
    border: 0;
  }

  nav .mobile-sidebar-logo {
    width: 168px;
    height: 11px;
  }

  nav .mobile-sidebar-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  nav .small-header {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(30px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 15px 20px;
    margin-bottom: 40px;
  }

  nav .navbar-nav {
    padding: 0 15px;
  }

  nav .navbar-nav li {
    margin-bottom: 40px;
  }

  nav .navbar-nav li a,
  .sm-horizontal {
    position: relative;
  }

  nav .navbar-nav li a::after,
  nav .navbar-nav li a.active::before {
    content: "";
    width: 100%;
    display: block;
    height: 1px;
    background-color: #202e4b;
    position: absolute;
    bottom: -10px;
  }

  nav .privacy-terms {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 40px;
  }

  nav .privacy-terms .sm-horizontal::after {
    content: "";
    display: block;
    top: -40px;
    position: absolute;
    background: rgba(255, 255, 255, 0.7);
    width: 100px;
    height: 1px;
    left: 50%;
    transform: translateX(-50);
  }

  /* nav end */

  /* checkout style */

  .checkout .col-lg-6.pl-30 {
    padding-left: 0 !important;
  }

  .checkout .col-lg-6.mb-30,
  .checkout .col-lg-12.mb-30 {
    margin-bottom: 20px;
  }

  .checkout .mb-240 {
    margin-bottom: 240px;
  }

  .checkout .orderCard {
    padding: 20px;
  }

  .checkout .inner-box.mb-30 {
    margin-bottom: 20px;
  }

  /* checkout style end */
}

@media (max-width: 767px) {
  .topNav .horizontalLine::after {
    display: none;
  }

  .topHeader .breadCrumb,
  .topNav {
    margin-bottom: 40px;
  }

  .topHeader .input-with-icon .search-btn {
    height: 40px;
    font-size: 16px;
    width: 120px;
  }

  .gemCard .gemBody {
    padding: 20px;
  }

  .Home .gemText {
    margin-top: 40px;
  }

  .card-section {
    padding-left: 0;
  }

  .gemCard .gradientBtn {
    width: 140px;
    font-size: 14px;
  }

  .Home .gemText {
    font-size: 22px;
  }

  .Home .gemText .gradientText {
    font-size: 18px;
    margin-left: 10px;
  }

  .Home .sortBtn,
  .topHeader .input-with-icon {
    height: 50px;
  }

  .fs-25 {
    font-size: 18px;
  }

  footer {
    text-align: center;
    font-size: 18px;
    padding-bottom: 60px;
  }

  footer .privacy-links a {
    font-size: 12px;
  }

  footer .topBorder {
    padding-top: 50px;
  }

  .Home {
    margin-bottom: 50px;
    padding-bottom: 100px;
  }

  footer .privacy-links {
    justify-content: center;
  }

  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
  }

  .pagination .no-page {
    font-weight: 700;
    font-size: 13px;
    line-height: 17px;
    color: #fff;
  }

  .pagination .arrows {
    display: flex;
    align-items: center;
  }

  .arrows .arrowLeft,
  .arrows .arrowRight {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input-with-icon input::placeholder {
    font-size: 12px;
  }

  .input-with-icon img {
    top: 4px;
    left: 15px;
  }

  .stakeVault .legendryBtn-green {
    width: 100%;
    margin-top: 20px;
  }

  .stakeVault .legendryBtn-green .innerText {
    font-size: 16px;
  }

  .stakeVault .gradientBtn {
    width: 100%;
  }

  .stakeVault .itemHeading {
    font-size: 18px;
  }

  .stakeVault .fs-18,
  .stakeVault .stakeCards .stake-heading {
    font-size: 13px;
  }

  .stakeVault .fs-36 {
    font-size: 25px;
  }

  .stakeVault .stakeBtn {
    width: 84px;
  }

  .stakeVault .stakeAllBtn {
    width: 126px;
  }

  .stakeVault .stakeCards {
    padding: 20px;
  }

  .stakeVault .stakeCards .stake-innerCard {
    padding: 10px;
  }

  .productDetail .productCard .cardHeading {
    font-size: 18px;
  }

  .productDetail .productCard .productImg {
    height: 240px;
  }

  .productDetail .detailBadge {
    top: 10px;
    right: 5px;
  }

  .viewCart .cartCard {
    padding: 10px;
  }

  .cartCard .cart-img {
    width: 90px;
    height: 70px;
    padding: 5px;
  }

  .cartCard .fs-16 {
    font-size: 12px;
  }

  .cartCard .description {
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 171px;
  }

  .connectBtn {
    font-size: 14px;
  }

  .Home .home-pagination {
    bottom: -100px;
  }

  .home-pagination .page-item.disabled .page-link,
  .home-pagination .page-item .page-link {
    background-color: transparent;
    font-weight: 400;
    font-size: 9px;
    line-height: 32px;
    letter-spacing: 0.05em;
    color: #fff;
  }

  /* landing page responsive */

  .landingPage .header {
    background-image: url("./assets/header-mobile.png");
    height: 812px;
  }

  .landingPage .connectBtn {
    width: 340px;
    height: 50px;
    margin-top: 70px;
  }

  .landingPage .topHeading {
    font-size: 66px;
    line-height: 70px;
    margin-top: 150px;
  }

  .upcoming-events .upcoming-img .img-upper-text {
    width: 100%;
    font-size: 11px;
    left: 10px;
    bottom: 15px;
  }

  .landingPage .About-us {
    padding: 0;
  }

  .landingPage .About-us .mb-70 {
    margin-bottom: 35px;
  }

  .landingPage .heading {
    font-size: 25px;
    line-height: 36px;
  }

  .fs-25 {
    font-size: 16px;
  }

  .landingPage .About-us,
  .landingPage .housestakeBg,
  .landingPage .upcoming-events {
    height: auto;
    padding-bottom: 50px;
  }

  .landingPage .gemsVault .vaultCard {
    height: 300px;
  }

  .landingPage .vaultCard .vault-img {
    height: 200px;
  }

  .landingPage .vaultCard .fs-36 {
    font-size: 18px;
  }

  .landingPage .vaultCard .fs-22 {
    font-size: 14px;
  }

  .landingPage .upcoming-events .upcoming-img {
    height: auto;
  }

  .landingPage .About-us .slick-list,
  .landingPage .About-us .slick-slider,
  .landingPage .About-us .slick-track {
    right: 10px !important;
    width: 345px;
  }

  .landingPage .slick-prev {
    right: 70px !important;
  }

  .landingPage .stakeNft .stakeCard:not(:last-child) {
    margin-right: 10px;
  }

  /* landing page responsive end */

  /* checkout style */

  .checkout .col-lg-6.mb-30,
  .checkout .col-lg-12.mb-30 {
    margin-bottom: 15px;
  }

  .checkout .orderCard {
    padding: 20px 10px;
  }

  .checkout .inner-box.mb-30 {
    margin-bottom: 15px;
  }

  /* checkout style end */

  /* faq style*/

  .faq .faqTabs .nav-tabs {
    overflow-x: auto;
    flex-wrap: nowrap;
    justify-content: flex-start !important;
  }

  .faq .faqTabs .nav-tabs::-webkit-scrollbar {
    display: none;
  }

  .faqTabs .nav-tabs .nav-item:not(:last-child) {
    margin-right: 20px;
  }

  .faq .faqCard {
    padding: 20px 15px;
    margin: 0 -15px;
  }

  .faq .fs-36 {
    font-size: 25px;
  }

  .faq .fs-36.mb-80 {
    margin-bottom: 40px;
  }

  .faqTabs .nav-tabs .nav-link.active::after {
    height: 3px;
  }

  .faq .faq-open-icon {
    top: 40px;
    right: -15px;
  }

  .question summary {
    font-size: 18px;
    padding-right: 20px;
  }

  /* faq style end*/
}

/* @media (min-width: 992px) {
  .container1 {
    max-width: 1000px;
  }
} */

iframe {
  display: none !important;
}

/* modal style */
.modal-dialog.customModal .modal-content {
  border-radius: 20px;
}

.modal-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #202e4b;
  box-shadow: 0px 8px 50px rgb(0 0 0 / 20%);
  height: 90px;
  padding: 0 30px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.modal-body {
  background: #0a090f;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.modal-body .textWhite70 {
  color: rgba(255, 255, 255, 0.7);
}

.modal-body .connectBtn {
  background: #55dbcb;
  border-radius: 0 !important;
  color: #0a090f;
  width: 100%;
}

.modal-body .icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.modal-body .icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.modal-body .modal-input {
  position: relative;
}

.modal-input input {
  background: rgba(255, 255, 255, 0.05);
  border: 0.5px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 70px;
  padding-left: 30px;
  color: #fff;
}

.modal-body .modal-input .gems {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #55dbcb;
  position: absolute;
  top: 26px;
  right: 30px;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #030209 !important;
}

.topNav .navbar-brand img {
  width: 92%;
  height: 100%;
  object-fit: contain;
}

/* modal style end */

@media (min-width: 1024px) and (max-width: 1399px) {
  .bg-blur {
    height: 57px;
  }

  .topNav nav .nav-link {
    font-size: 12px;
    line-height: 20px;
  }

  .topNav .horizontalLine::after {
    bottom: -13px;
  }

  .topNav .navbar-brand {
    width: 210px;
    height: auto;
  }

  .topNav .navbar-brand img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .landingPage .progres {
    width: 200px;
    left: 90px;
  }

  .landingPage .slick-next {
    top: 100% !important;
    right: 88px !important;
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .topNav .navbar-brand img {
    width: 163px;
    object-fit: inherit;
  }

  .navbar-dark .navbar-brand {
    padding: 0;
  }

  .bg-blur {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(30px);
    height: 66px;
  }

  .socialIcons {
    margin-left: 29% !important;
  }

  nav .privacy-terms .sm-horizontal::after {
    left: 38%;
  }

  .landingPage .topHeading .bannerText {
    font-size: 32px;
    line-height: 40px;
  }

  .landingPage .topHeading {
    margin-left: 0px;
  }

  .landingPage .topHeading .bannerTextDes {
    font-size: 22px;
  }

  .landingPage .header .connectBtn {
    font-size: 10px;
    margin: 0 auto;
  }

  .landingPage .header {
    height: 714px;
    background-size: cover;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .landingPage .topHeading .bannerText {
    font-size: 60px;
    margin-top: 26%;
  }

  .landingPage .topHeading {
    padding-right: 30px;
  }

  .landingPage .header {
    background-size: cover;
    height: 578px;
  }

  .bg-blur {
    height: 66px;
  }

  .topNav .navbar-brand img {
    width: 309px;
    height: 100% !important;
  }
}