.orderHistory {
    margin: 87px 0;
}


.orderHistory .order-primary-card {
    background: #202E4B;
    border-radius: 10px;
    padding: 40px;
}

.orderHistory .border-line {
    border: 0.5px solid rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    padding: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.orderHistory .dp-img {
    border: 0.5px dashed #55DBCB;
    border-radius: 4px;
    width: 80px;
    height: 80px;
    padding: 5px;
}

.orderHistory .dp-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.orderHistory .btnGreen {
    background: #A6FFB9;
    border-radius: 44px;
}

.orderHistory .btnRed {
    background: #FFA5A5;
    border-radius: 44px;

}

.orderHistory .btnGreen .innerText, .orderHistory .btnRed .innerText {
    background: linear-gradient(90.03deg, rgba(3, 2, 9, 0.7) 0.02%, rgba(8, 26, 62, 0.7) 99.96%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.05em;

}

.orderHistory .text-Green {
    color: #6AFF8B;
}

.orderHistory .text-light-red {
    color: #FFA5A5;
}

.orderHistory .line-clamp{
    width: 310px;
}

.orderHistory .greenDot{
    background-color: #6AFF8B;
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 50%;
}

.orderHistory .redLightDot{
    background-color: #FFA5A5;
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 50%;
}

@media  (max-width : 768px) {
    .orderHistory .order-primary-card{
        padding: 15px;
    }

    .orderHistory .border-line {
        padding: 10px;
        display: block;
    }

    .orderHistory .dp-img{
        width: 75px;
        height: 55px;
    }

    .orderHistory .btnGreen .innerText, .orderHistory .btnRed .innerText{
        font-size: 10px;
    }


    .orderHistory .line-clamp{
        width: 145px;
    }
    
}